#quem {
  border-top: 3px solid @white;
  width: 100%;
  background: #F2F2F2;

  .pics-who {
    width: 90%;
    margin: 40px auto 0;

    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .inner-pics-who {
      width: 78%;
      height: auto;
    }

    .pics-who-item {
      width: 24%;
      height: 282px;
      float: left;
      background: transparent no-repeat center left;
      background-size: cover;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin: 0 0 1.25%;
      padding-bottom: 25px;
      .transition(all .3s ease);
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
    
      &:nth-child(4n + 2) {
        margin-left: 1.25%;
        margin-right: 1.25%;
      }
      &:nth-child(4n + 3) {
        margin-right: 1.25%;
      }

      &:hover {
        -webkit-filter: none; /* Safari 6.0 - 9.0 */
          filter: none;
      }
    }
  }

  .title-pics-who {
    display: block;
    width: 70%;
    font-size: 32px;
    line-height: 30px;
    margin-bottom: 20px;
    margin-left: 0;
    position: relative;
    color: @white;

    &::before {
      content: '';
      width: 35px;
      height: 2px;
      background: @tertiary;
      display: block;
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }

  .pics-who-profession {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: @font-fifth;
    color: @white;
    margin-bottom: 5px;
  }

  .social-who {
    padding: 0;
    margin: 10px 0 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 5px;

      &:first-of-type {
        margin-left: 0;

        img {
          max-width: 13px;
        }
      }
    }

    a {
      display: block;
      .transition(all .3s ease);

      &:hover {
        .rotate(15deg);
      }
    }

    img {
      max-width: 20px;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .pics-who {
      width: 100%;

      .pics-who-item {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 40px;
      }
    }
  }
}