
.transition(@value) {
  -webkit-transition: @value;
  -moz-transition: @value;
  -ms-transition: @value;
  -o-transition: @value;
  transition: @value;
}

.opacity(@value) {
  opacity: @value;
}

.box-shadow(@value) {
  -webkit-box-shadow: @value;
  -moz-box-shadow:  @value;
  box-shadow: @value;
}

.clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.border-radius(@value) {
  -webkit-border-radius: @value;
  -moz-border-radius: @value;
  border-radius: @value;
}

.translate(@value1, @value2) {
  -webkit-transform: translate(@value1, @value2);
  -moz-transform:    translate(@value1, @value2);
  -ms-transform:     translate(@value1, @value2);
  -o-transform:      translate(@value1, @value2);
  transform:         translate(@value1, @value2);
}

.placeholder(@font-size: 14px, @color: @general-text, @text-transform: none) {
  // Firefox
  &::-moz-placeholder {
    font-family: @font-quaternary;
    font-size: @font-size;
    color: @color;
    font-weight: 400;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
    text-transform: @text-transform;
  }
  &:-ms-input-placeholder {
    font-family: @font-quaternary;
    color: @color;
    font-size: @font-size;
    font-weight: 400;
    text-transform: @text-transform;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder  {
    font-family: @font-quaternary;
    color: @color;
    font-size: @font-size;
    font-weight: 400;
    text-transform: @text-transform;
  } // Safari and Chrome
}

.rotate(@degrees) {
  -webkit-transform: rotate(@degrees);
  -ms-transform: rotate(@degrees); // IE9 only
  -o-transform: rotate(@degrees);
  transform: rotate(@degrees);
}