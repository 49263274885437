/*
 * Crete Round
 */
@font-face {
  font-family: 'Crete Round';
  src: url("@{base-url}fonts/CreteRound-Italic.woff2") format('woff2'),
  url("@{base-url}fonts/CreteRound-Italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Crete Round';
  src: url("@{base-url}fonts/CreteRound-Regular.woff2") format('woff2'),
  url("@{base-url}fonts/CreteRound-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Crete';
  src: url("@{base-url}fonts/Crete-Regular.woff2") format('woff2'),
  url("@{base-url}fonts/Crete-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

/*
 * Helvetica
 */
@font-face {
  font-family: 'HelveticaNeueLT Com 53 Ex';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-ExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-ExO.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 93 BlkEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-BlkEx.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-BlkEx.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 37 ThCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-ThCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-ThCn.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 65 Md';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Md.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Md.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 97 BlkCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-BlkCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-BlkCn.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 57 Cn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Cn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Cn.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 75 BdOu';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-BdOu.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-BdOu.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 37 ThCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-ThCnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-ThCnO.woff") format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 23 UltLtEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-UltLtEx.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-UltLtEx.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 97 BlkCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-BlkCnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-BlkCnO.woff") format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 27 UltLtCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-UltLtCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-UltLtCn.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 67 MdCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-MdCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-MdCn.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 45 Lt';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Lt.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Lt.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 47 LtCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-LtCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-LtCn.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 33 ThEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-ThExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-ThExO.woff") format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 93 BlkEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-BlkExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-BlkExO.woff") format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 57 Cn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-CnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-CnO.woff") format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 53 Ex';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Ex.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Ex.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 67 MdCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-MdCnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-MdCnO.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 23 UltLtEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-UltLtExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-UltLtExO.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 63 MdEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-MdExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-MdExO.woff") format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 25 UltLt';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-UltLt.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-UltLt.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 95 Blk';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Blk.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Blk.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 43 LtEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-LtExO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-LtExO.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 33 ThEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-ThEx.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-ThEx.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 63 MdEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-MdEx.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-MdEx.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 35 Th';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Th.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Th.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 107 XBlkCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-XBlkCn.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-XBlkCn.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 27 UltLtCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-UltLtCnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-UltLtCnO.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 55 Roman';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-Roman.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-Roman.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 43 LtEx';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-LtEx.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-LtEx.woff") format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLT Com 47 LtCn';
  src: url("@{base-url}fonts/HelveticaNeueLTCom-LtCnO.woff2") format('woff2'),
  url("@{base-url}fonts/HelveticaNeueLTCom-LtCnO.woff") format('woff');
  font-weight: 300;
  font-style: italic;
}

