header.header {
  width: 100%;
  height: 99px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: transparent;
  .transition(background .3s ease);

  .header-inner {
    padding: 25px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .transition(all .3s ease);
  }

  .logo {
    width: 245px;
    display: inline-block;
    vertical-align: top;

    a {
      display: inline-block;
      vertical-align: top;
      text-decoration: none
    }
    .logo-circle {
      width: 37px;
    }
    .logo-text {
      width: 183px;
      position: relative;
      top: 3px;
    }

    &:hover {
      .logo-circle {
        -webkit-animation: moveLogo 1s infinite; /* Safari 4.0 - 8.0 */
        animation: moveLogo 1s infinite;
      }
    }
  }

  .menu {
    width: 665px;
    display: inline-block;
    vertical-align: top;

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 25px;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      &:nth-child(1) a.active,
      &:nth-child(1) a:hover {
        background: @gray-lighten;
      }
      &:nth-child(2) a.active,
      &:nth-child(2) a:hover {
        background: @primary;
      }
      &:nth-child(3) a.active,
      &:nth-child(3) a:hover {
        background: @tertiary;
      }
      &:nth-child(4) a.active,
      &:nth-child(4) a:hover {
        background: @secondary;
      }
      &:nth-child(5) a.active,
      &:nth-child(5) a:hover {
        background: @fifth;
      }
      &:nth-child(6) a.active,
      &:nth-child(6) a:hover {
        background: @quaternary;
      }
      &:nth-child(7) a.active,
      &:nth-child(7) a:hover {
        background: @sixth;
      }
    }

    a {
      margin-top: 3px;
      font-size: 16px;
      font-weight: 400;
      height: 10px;
      display: block;
      line-height: 10px;
      font-family: @font-secondary;
    }
  }

  .menu-social {
    width: 110px;
    display: inline-block;
    vertical-align: top;

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 5px;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      &:nth-child(1) a:hover {
        background: @primary;
      }
      &:nth-child(2) a:hover {
        background: @secondary;
      }
      &:nth-child(3) a:hover {
        background: @tertiary;
      }
      &:nth-child(4) a:hover {
        background: @quaternary;
      }
    }

    li:first-of-type {
      img {
        max-height: 20px;
      }
    }

    li:not(:first-of-type) {
      img {
        max-width: 22px;
      }
    }

    a {
      display: block;
      width: 20px;
      height: 20px;
      display: block;
      line-height: 10px;
      background: transparent;
      text-align: center;

      &:hover {
        background: @gray-lighten;
      }
    }
  }

  .hamburger-menu {
    display: none;
    width: 35px;
    height: auto;
    float: right;
    position: relative;

    span {
      width: 100%;
      height: 3px;
      margin-bottom: 5px;
      display: block;
      background: @general-text;
      .transition(all .3s ease);
    }
  }

  &.scrolled {
    height: 69px;
    background: @white;

    .header-inner {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  @media screen and (max-width: @screen-md-max) {
    .header-inner {
      padding: 15px 10px;
    }

    .menu {
      width: 545px;

      li {
        margin: 0 15px;
      }
    }
  }
  
  @media screen and (max-width: @screen-sm-max) {
    height: 69px;
    background: @white;

    .header-inner {
      height: 100%;
      padding: 0 15px;
    }

    .logo {
      min-width: 200px;

      .logo-text {
        width: 180px;
      }
    }

    .hamburger-menu {
      display: block;
    }

    .menu {
      width: calc(100vw);
      height: calc(100vh);
      position: fixed;
      top: 0;
      left: -100vw;
      background: @white;
      .transition(left .3s linear);

      .menu-items {
        margin-top: 80px;
        padding: 0 20px;
      }

      li {
        margin: 0 0 20px;
        display: block;

        a {
          display: inline-block;
          font-size: 34px;
          line-height: 14px;
        }
      }
    }

    .menu-social {
      display: none;
    }

    &.menu-opened {
      .menu {
        left: 0;
      }
    }

    .close-button {
      position: absolute;
      right: 10px;
      top: 0;
    }

    .menu-social.only-mobile {
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 0;

      li {
        display: inline-block;
        margin: 0 10px;

        &:first-of-type img {
          max-height: 35px;
        }
        &:not(:first-of-type) img {
          max-width: 40px;
        }

        a {
          display: block;
          width: 40px;
          height: 35px;
        }
      }
    }
  }
}


@keyframes moveLogo {
  0% {
    .rotate(0deg);
  }
  100% {
    .rotate(360deg);
  }
}