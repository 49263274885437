body, html {
  font-size: 14px;
  font-family: @font-base;
  font-weight: 400;
  color: @gray;
  background: @white;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  margin: 0;
  padding: 0;

  * {
    font-weight: 400;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

::-moz-selection { /* Code for Firefox */
  color: @white;
  background: @tertiary;
}
::selection {
  color: @white;
  background: @tertiary;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
  outline: none;
}

a {
  .transition(all .3s ease);
  color: @general-text;
  text-decoration: none;
  outline: none;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 600;
}

.text-upper {
  text-transform: uppercase;
  font-weight: 300;
}

.text-right {
  text-align: right;
}

[class^="text-label-"] {
  display: inline-block;
  position: relative;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 8px;
    width: 100%;
    height: 18px;
    background: @gray-lighten;
  }
}
.text-label-1::before {
  background: @primary;
}
.text-label-2::before { 
  background: @secondary;
}
.text-label-3::before { 
  background: @tertiary;
}
.text-label-4::before { 
  background: @quaternary;
}
.text-label-5::before { 
  background: @fifth;
}
.text-label-6::before { 
  background: @sixth;
}


.text-color-1 {
  color: @primary;
}
.text-color-2 { 
  color: @secondary;
}
.text-color-3 { 
  color: @tertiary;
}
.text-color-4 { 
  color: @quaternary;
}
.text-color-5 { 
  color: @fifth;
}
.text-color-6 { 
  color: @sixth;
}

.inner-content {
  width: 100%;
  padding: 60px 40px;
}

.content-text {
  width: 60%;
  margin: 0 auto;

  p {
    font-size: 20px;
    line-height: 24px;
    font-family: @font-quaternary;
    margin: 0;
  }
}

.generic-title {
  font-size: 72px;
  margin: 0 0 40px;
  padding: 0;
  font-style: italic;
  font-family: @font-secondary;
}

#loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: @general-text;
  .transition(all .4s ease);

  img {
    width: 60px;
    -webkit-animation: moveLogo 1.5s infinite; /* Safari 4.0 - 8.0 */
    animation: moveLogo 1.5s infinite;
  }

  &.loader-done {
    .opacity(0);
    z-index: -1;
  }
}

.only-mobile {
  display: none !important;
}

.close-button {
  display: block;
  width: 40px;
  height: 60px;
  float: left;
  position: absolute;
  z-index: 10;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 20px;
    left: 2px;
    width: 35px;
    height: 1px;
    background: @general-text;
    .transition(all .3s ease);
  }

  &::before {
    .rotate(45deg);
  }
  &::after {
    .rotate(-45deg);
  }

  span {
    display: block;
    padding-top: 40px;
    font-size: 14px;
    color: @general-text;
  }

  &:hover {
    &::before {
      .rotate(25deg);
    }
    &::after {
      .rotate(-25deg);
    }
  }
}

@media screen and (max-width: @screen-sm-max) {
  .only-mobile {
    display: block !important;
  }

  .no-mobile {
    display: none !important;
  }

  .inner-content {
    padding: 60px 20px;
  }

  .content-text {
    width: 100%;
  }
}

@keyframes moveLogo {
  0% {
    .rotate(0deg);
  }
  100% {
    .rotate(360deg);
  }
}