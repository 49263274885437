
// Initial Colors
@primary:         #3BFFAF;
@secondary:       #65CCE3;
@tertiary:        #EA6198;
@quaternary:      #FFC152;
@fifth:           #D798C5;
@sixth:           #FFFF3E;
@seventh:         #3C476F;


@white:           #FFFFFF;
@white-clean:     #f1f1f1;
@black:           #000000;

@bg-fields:       #E1E1E1;

@gray:            #4a4a4a;
@gray-medium:     #E5E5E5;
@gray-light:      #dee0e8;
@gray-lighten:    #B3B3B3;
@gray-base:       #000;
@gray-darker:     #1B1B1B;
@gray-dark:       #333;
@gray-lighter:    #eceef6;

@gray-title:      #A7A9AD;

@general-text:            #333333;
@pagination-text:         #A7A9AC;

@text-header:     @general-text;

@success-color:   @secondary;

@brand-primary:   @primary;
@brand-success:   @secondary;
@brand-info:      #5bc0de;
@brand-warning:   #f0ad4e;
@brand-danger:    #d9534f;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-base:               'Crete', sans-serif;
@font-secondary:          'Crete Round', sans-serif;
@font-tertiary:           'HelveticaNeueLT Com 97 BlkCn', sans-serif;
@font-quaternary:         'HelveticaNeueLT Com 45 Lt', sans-serif;
@font-fifth:              'HelveticaNeueLT Com 47 LtCn', sans-serif;
@font-sixth:              'HelveticaNeueLT Com 65 Md', sans-serif;
@font-seventh:            'HelveticaNeueLT Com 55 Roman', sans-serif;

@font-size-base:          12px;
@font-size-large:         ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:         ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1:            floor((@font-size-base * 2.6)); // ~36px
@font-size-h2:            floor((@font-size-base * 2.15)); // ~30px
@font-size-h3:            ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    inherit;
@headings-font-weight:    500;
@headings-line-height:    1.1;
@headings-color:          inherit;




//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            @general-text;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: underline;




//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

@border-radius-base:            @bg-button;

@bg-button:                      @primary;
@btn-font-weight:                normal;

@btn-default-color:              #84858a;
@btn-default-bg:                 #fff;
@btn-default-border:             #9b9b9b;

@btn-primary-color:              #fff;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;

// Allows for customizing button radius independently from global border radius
@btn-border-radius-base:         @border-radius-base;
@btn-border-radius-large:        @border-radius-large;
@btn-border-radius-small:        @border-radius-small;


// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);



//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
@grid-columns:              12;
//** Padding between columns. Gets divided in half for the left and right.
@grid-gutter-width:         30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
@grid-float-breakpoint:     @screen-sm-min;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);



//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
@container-tablet:             (720px + @grid-gutter-width);
//** For `@screen-sm-min` and up.
@container-sm:                 @container-tablet;

// Medium screen / desktop
@container-desktop:            (940px + @grid-gutter-width);
//** For `@screen-md-min` and up.
@container-md:                 @container-desktop;

// Large screen / wide desktop
@container-large-desktop:      (1000px + @grid-gutter-width);
//** For `@screen-lg-min` and up.
@container-lg:                 @container-large-desktop;




//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
@padding-top-containers:    3%;

@padding-base-vertical:     18px;
@padding-base-horizontal:   32px;

@padding-large-vertical:    10px;
@padding-large-horizontal:  16px;

@padding-small-vertical:    5px;
@padding-small-horizontal:  10px;

@padding-xs-vertical:       1px;
@padding-xs-horizontal:     5px;

@line-height-large:         1.3333333; // extra decimals for Win 8.1 Chrome
@line-height-small:         1.5;

@border-radius-base:        3px;
@border-radius-large:       6px;
@border-radius-small:       3px;



//** Global color for active items (e.g., navs or dropdowns).
@component-active-color:    #fff;
//** Global background color for active items (e.g., navs or dropdowns).
@component-active-bg:       @brand-primary;

//** Width of the `border` for generating carets that indicate dropdowns.
@caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
@caret-width-large:         5px;



//== Thumbnails
//
//##
@base-url:                '../../../../../dist/';

//** Padding around the thumbnail image
@thumbnail-padding:           4px;
//** Thumbnail background color
@thumbnail-bg:                @body-bg;
//** Thumbnail border color
@thumbnail-border:            #ddd;
//** Thumbnail border radius
@thumbnail-border-radius:     @border-radius-base;

//** Custom text color for thumbnail captions
@thumbnail-caption-color:     @text-color;
//** Padding around the thumbnail caption
@thumbnail-caption-padding:   9px;

@hr-border:                   @gray-lighter;