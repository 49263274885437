#contato {
  border-top: 3px solid @white;
  width: 100%;
  background: #F2F2F2;

  .contact-mail {
    font-weight: 600;
    font-family: @font-sixth;
  }

  .contact-container {
    width: 100%;
    margin-top: 40px;

    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .side-left {
    float: left;
    width: 30%;

    address {
      font-style: normal;
      margin-bottom: 40px;
  
      .local-title {
        font-family: @font-quaternary;
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-bottom: 10px;
  
        strong, span {
          font-size: 20px;
          font-weight: 600;
          font-family: @font-sixth;
        }
      }

      .line-contact {
        display: block;
        margin-bottom: 3px;
        font-weight: 400;
        font-family: @font-seventh;
        font-size: 16px;
        line-height: 17px;
        max-width: 160px;
      }
    }
  }

  .side-right {
    float: left;
    width: 70%;
  }

  @media screen and (max-width: @screen-sm-max) {
    .side-left, .side-right {
      width: 100%;
    }

    .side-right {
      div.wpcf7 .ajax-loader {
        text-align: center;
        margin: 10px auto 0 !important;
        position: relative;
        display: block;    
      }

      .wpcf7-response-output {
        position: relative;
        width: 100%;
        margin-top: 30px !important;
        font-size: 16px;
      }
    }
  }
}