
.form-group {
  margin-bottom: 8px;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.form-control {
  width: 100%;
  min-height: 40px;
  border: 0;
  font-family: @font-quaternary;
  font-size: 20px;
  border-bottom: 0;
  padding: 20px 15px;
  color: @general-text;
  outline: none;
  background: #E6E6E6;
  .placeholder(20px, @general-text);

  &.error, &.wpcf7-not-valid {
    border-color: @brand-danger;

    .placeholder(20px, @brand-danger);
  }
}

.file-control {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  display: block;

  input {
    .opacity(0);
    position: absolute;
    z-index: -1;
  }

  span {
    cursor: pointer;
    font-size: 16px;
    color: @secondary;
  }
}

label.error,
span.wpcf7-not-valid-tip {
  margin: 5px;
  display: block;
  font-size: 12px !important;
}

.label {
  position: absolute;
  top: 25px;
  left: 0;
  color: @general-text;
  .transition(all .2s ease);

  span {
    color: @primary;
    position: relative;
    top: -1px;
    margin-right: 2px;
  }
}

label {
  color: @general-text;
}

.focused-field {
  .label {
    font-size: 10px;
    top: 6px;
  }
}

textarea.form-control {
  resize: none;
  min-height: 150px;
}

.message-box {
  margin-top: 30px;
}

.checkbox-custom {
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  padding-left: 10px;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .opacity(0);
    z-index: 10;

    + .wpcf7-list-item-label {
      position: relative;

      &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 4px;
        position: relative;
        background: transparent;
        border: 2px solid @secondary;
        top: 3px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 7px;
        left: 4px;
        display: block;
        width: 7px;
        height: 7px;
        background: @secondary;
        .opacity(0);
        .transition(opacity .3s ease);
      }
    }

    &:checked + .wpcf7-list-item-label:after {
      .opacity(1);
    }
  }

  span {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    color: @white;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;

    &:not(.wpcf7-list-item-label) {
      padding-top: 3px;
    }
  }

  .wpcf7-not-valid-tip {
    text-transform: none;
    position: absolute;
    margin-left: 10px;
  }
}

.radio-custom {
  label {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    padding-left: 15px;
    margin-bottom: 10px;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      .opacity(0);
      z-index: 10;

      + .wpcf7-list-item-label {
        position: relative;

        &:before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 15px;
          margin-right: 5px;
          position: relative;
          background: transparent;
          border: 2px solid @secondary;
          top: 3px;
          .border-radius(50%);
        }

        &:after {
          content: '';
          position: absolute;
          top: 7px;
          left: 4px;
          display: block;
          width: 7px;
          height: 7px;
          background: @secondary;
          .opacity(0);
          .transition(opacity .3s ease);
          .border-radius(50%);
        }
      }

      &:checked + .wpcf7-list-item-label:after {
        .opacity(1);
      }
    }

    span {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      color: @white;
      font-size: 12px;
      line-height: 14px;
      font-weight: normal;

      &:not(.wpcf7-list-item-label) {
        padding-top: 3px;
      }
    }
  }

  .wpcf7-not-valid-tip {
    text-transform: none;
    position: absolute;
    margin-left: 10px;
  }
}

.radio-inline {
  > label {
    display: inline-block;
    vertical-align: middle;
    width: 49%;
  }
}

span.wpcf7-list-item {
  margin: 0 !important;
}

.select-custom {
  position: relative;
  width: 100%;

  .wpcf7-form-control-wrap {
    position: unset;
  }

  &.error {
    border-color: @brand-danger;
  }

  &:after {
    content: '';
    width: 22px;
    height: 10px;
    background: url("@{base-url}img/arrow-down-select.png") no-repeat;
    position: absolute;
    top: 18px;
    right: 10px;
    z-index: 12;
  }

  select {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    padding: 0 13px;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    color: @general-text;
    font-weight: 400;
    .border-radius(0);

    &:focus {
      outline: none;
    }

    option {
      padding: 10px;
      background: #000;
    }
  }

  &.required {
    select {
      padding-left: 19px;
    }

    &:before {
      content: '*';
      position: absolute;
      top: 12px;
      left: 10px;
      color: @primary;
    }
  }

  label.error {
    position: absolute;
    bottom: -20px;
    left: 0;
  }

  @media screen and (max-width: @screen-sm-max) {
    select {
      width: 100%;
      padding-right: 35px;
    }
  }
}

.container-messages {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;

  span {
    vertical-align: middle;
    display: block;
    overflow: hidden;
    padding: 0;
    height: 0;
    .opacity(0);
    .transition(all .3s ease);
  }

  .show {
    padding: 10px;
    .opacity(1);

    &.success-message {
      height: 40px;
    }
    &.error-message {
      height: 60px;
    }
  }

  .success-message {
    color: @success-color;
    background: lighten(@success-color, 50%);
  }
  .error-message {
    color: @primary;
    background: lighten(@primary, 50%);
  }
}

div.wpcf7 .ajax-loader {
  background-color: transparent;
  margin-left: 30px !important;
}

.wpcf7-form {
  position: relative;
  width: 100%;
  display: block;

  span.wpcf7-not-valid-tip {
    display: none;
  }
}

.wpcf7-response-output {
  border: 0 !important;
  color: red;
  display: block;
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 60%;
  min-width: 60%;
  margin: 0 !important;
  padding: 0 !important;
  text-align: right;
  font-family: @font-quaternary;

  &.wpcf7-mail-sent-ok {
    color: #398f14 !important;
  }
}