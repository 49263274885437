#clientes {
  border-top: 3px solid @white;
  width: 100%;
  background: #F2F2F2;

  .customers-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 15px 20px;

      &:nth-child(5n + 1) {
        margin-left: 0;
      }

      &:nth-child(5n + 5) {
        margin-right: 0;
      }
    }

    img {
      max-width: 120px;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .customers-list {
      text-align: center;

      li {
        width: 49.5%;
        margin: 0 0 20px;
      }
    }
  }
}