footer.footer {
  background: #4c4c4c; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #4c4c4c 0%, #424242 13%, #3d3d3d 25%, #3d3d3d 34%, #2c2c2c 42%, #2b2b2b 51%, #262626 61%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #4c4c4c 0%,#424242 13%,#3d3d3d 25%,#3d3d3d 34%,#2c2c2c 42%,#2b2b2b 51%,#262626 61%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #4c4c4c 0%,#424242 13%,#3d3d3d 25%,#3d3d3d 34%,#2c2c2c 42%,#2b2b2b 51%,#262626 61%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .inner-footer {
    width: 100%;
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }

  .part-one {
    img {
      width: 127px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .part-two {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        margin-bottom: 4px;
      }

      a {
        font-size: 12px;
        color: @white-clean;
      }
    }
  }

  .part-three {

    address {
      display: block;
      color: @white-clean;
      margin-bottom: 20px;
      font-size: 12px;
      font-style: normal;

      strong {
        font-weight: 600;
      }

      span {
        display: block;
        font-family: @font-quaternary;
        line-height: 20px;
      }

      .title-address {
        font-style: italic;
      }
    } 
  }

  .part-four {
    width: 360px;

    .social-footer {
      padding: 0;
      margin: 20px 0 0;
      list-style: none;
  
      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 5px;
  
        &:first-of-type {
          margin-left: 0;
  
          img {
            max-width: 13px;
          }
        }
      }
  
      a {
        display: block;
        .transition(all .3s ease);
  
        &:hover {
          .rotate(15deg);
        }
      }
  
      img {
        max-width: 20px;
      }
    }

    .contact-mail {
      margin-top: 10px;
      display: block;
      font-size: 12px;
      color: @white-clean;
      font-family: @font-sixth;
    }

    .copyright {
      margin-top: 10px;

      * {
        font-size: 12px;
        line-height: 16px;
        font-family: @font-quaternary;
      }

      span {
        display: block;
        color: @white-clean;
      }

      a {
        color: @white-clean;
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .inner-footer {
      display: block;
      padding: 40px 20px;
    }

    [class^="part-"] {
      width: 100% !important;
      margin-bottom: 40px;
    }

    .part-two {
      ul {
        li {
          margin-bottom: 15px;
        }

        a {
          font-size: 29px;
          line-height: 32px;
        }
      }
    }

    .part-three {
      display: none;
    }

    .part-four {
      .social-footer {
        li {
          margin: 0 10px;
        }

        li:first-of-type img {
          max-width: 19px;
        }
  
        img {
          max-width: 30px;
        }
      }
    }

    .fb-page {
      display: none;
    }

    .contact-mail {
      font-size: 16px !important;
    }

    .copyright {
      span {
        font-size: 14px !important;
        margin-bottom: 6px;
      }
    }
  }
}
