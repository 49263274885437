#noticias {
  border-top: 3px solid @white;
  width: 100%;
  background: #f2f2f2;

  @media screen and (max-width: @screen-sm-max) {
  }
}

#blog {
  width: 100%;
  background: #f2f2f2;
  padding: 80px 0;

  .load-more-button {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  @media screen and (max-width: @screen-sm-max) {
    padding: 40px 0;
  }
}
.page-template-noticias {
  header.header {
    background: @white;
  }
}

.block-news {
  margin-bottom: 20px;

  &::before,
  &::after {
    content: "";
    display: table;
    clear: both;
  }

  .news-item {
    display: block;
    width: 32.5%;
    min-height: 280px;
    float: left;
    background: transparent no-repeat center;
    background-size: cover;
    color: @white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1.25%;

    &:nth-child(3n + 2) {
      margin-left: 1.25%;
      margin-right: 1.25%;
    }

    span,
    a {
      display: block;
      color: @white;
    }
  }

  .inner-news-item {
    width: 83%;
    height: 90%;
  }

  .date-item {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: @font-tertiary;
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .title-item {
    font-family: @font-secondary;
    margin: 0;
    padding: 10px;
    font-size: 32px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
    z-index: 2;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      background: @primary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      .opacity(0.7);
    }
  }
  .see-more-item {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: @font-tertiary;
    margin-top: 5px;
    margin-left: 5px;
  }

  @media screen and (max-width: @screen-sm-max) {
    .news-item {
      width: 100%;
      margin: 0 0 20px !important;
    }
  }
}

#blog-single {
  width: 100%;
  background: #f2f2f2;
  padding: 0;

  .header-post {
    width: 100%;
    padding: 10px 20px 20px;
    height: 80px;
    background: @white-clean;
    position: fixed;
    z-index: 9999;

    &::before,
    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .social-links {
    width: 55px;
    float: right;

    ul {
      padding: 0;
      list-style: none;
    }

    li {
      display: inline-block;
      vertical-align: top;
      margin: 0 0 0 5px;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      &:nth-child(1) a:hover {
        background: @primary;
      }
      &:nth-child(2) a:hover {
        background: @secondary;
      }
      &:nth-child(3) a:hover {
        background: @tertiary;
      }
      &:nth-child(4) a:hover {
        background: @quaternary;
      }
    }

    li:first-of-type {
      img {
        max-height: 20px;
      }
    }

    li:not(:first-of-type) {
      img {
        max-width: 22px;
      }
    }

    a {
      display: block;
      width: 20px;
      height: 20px;
      display: block;
      line-height: 10px;
      background: transparent;
      text-align: center;

      &:hover {
        background: @gray-lighten;
      }
    }
  }

  .title-news {
    color: @seventh;
  }

  .inner-post {
    width: 80%;
    margin: 0 auto;
    padding: 100px 0 80px;

    h1 {
      text-align: center;
      width: 100%;
      margin: 0 0 10px;
    }
    .date-post {
      display: block;
      text-transform: uppercase;
      font-size: 18px;
      font-family: @font-quaternary;
      text-align: center;
      width: 100%;
      margin: 0 0 30px;
    }
    .author-post {
      display: block;
    }
    .image-post {
      width: 100%;
      min-height: 450px;
      background-size: cover;
    }
    .content-post {
      font-size: 20px;
      line-height: 24px;
      font-family: @font-quaternary;
      margin: 0 0 15px;

      strong {
        font-weight: 600;
      }

      a {
        font-weight: 600;
        text-decoration: underline;
        color: @secondary;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .inner-post {
      width: 100%;
      padding: 100px 20px 80px;
    }
  }
}
.single-post {
  background: #f2f2f2;

  header.header {
    display: none !important;
  }
}
