#portfolio {
  border-top: 3px solid @white;
  width: 100%;
  background: #F2F2F2;

  .block-portfolio {
    margin-top: 60px;
  
    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  
    .portfolio-item {
      display: block;
      width: 32.5%;
      min-height: 280px;
      float: left;
      background: transparent no-repeat center;
      background-size: cover;
      color: @white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 1.25%;
  
      &:nth-child(3n + 2) {
        margin-left: 1.25%;
        margin-right: 1.25%;
      }
  
      span, a {
        display: block;
        color: @white;
      }
    }
  
    .inner-portfolio-item {
      width: 83%;
      height: 90%;
    }
  
    .local-item {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      font-family: @font-tertiary;
      margin-bottom: 5px;
      margin-left: 5px;
    }
    .title-item {
      font-family: @font-secondary;
      margin: 0;
      padding: 10px;
      font-size: 32px;
      font-weight: 400;
      line-height: 30px;
      position: relative;
      z-index: 2;
  
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        background: @quaternary;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        .opacity(0.7);
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .block-portfolio {
      .portfolio-item {
        width: 100%;
        margin: 0 0 20px !important;
      }
    }
  }
}