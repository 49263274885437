
.btn {
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  padding: 20px;
  border: 0;
  color: @general-text;
  text-align: center;
  font-family: @font-sixth;
  letter-spacing: 2px;
  display: inline-block;
  background-color: #E6E6E6;
  margin: 0 auto;
  .border-radius(0);
  .transition(all .3s ease);
  text-decoration: none;
  outline: none;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    background: darken(#E6E6E6, 5%);
    color: @general-text;
    text-decoration: none;
  }

  &.btn-full {
    width: 100%;
    display: block;
  }

  &.btn-outline {
    background: transparent;
    border: 1px solid @white;
    color: @white;

    &:focus,
    &:active,
    &:hover {
      background: @primary;
    }

    &.outline-secondary {
      &:focus,
      &:active,
      &:hover {
        background: @secondary;
      }
    }
  }

  &.btn-secondary {
    background: @gray-lighten;
    color: @white;

    &:hover {
      background: darken(@gray-lighten, 10%);
      color: @white;
    }
  }

  &.btn-smaller {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  &.btn-white {
    background: @white;
    color: @secondary;

    &.text-secondary {
      color: @primary;
    }
  }

  &.btn-bold {
    font-weight: 600;
  }

  &.btn-image {
    > * {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      position: relative;
      top: -2px;
      margin-right: 5px;
    }
  }

  &.btn-round {
    .border-radius(20px);
    padding-left: 28px;
    padding-right: 28px;
  }

  &[disabled] {
    background: darken(@secondary, 20%);
    color: @white;
  }

  &.btn-centered {
    max-width: 240px;
    display: block;
    margin: 40px auto 0;
    text-align: center;
  }

  @media screen and (max-width: @screen-sm-max) {
    max-width: none !important;
    width: 100%;
    height: 50px;
    padding: 10px;
    margin: 0 !important;
    font-size: 20px;
    line-height: 26px;
    color: @white;
    background: #B3B3B3;
    font-family: @font-quaternary;
    letter-spacing: 10px;
    
    &:hover, &:focus {
      color: @white;
      background: #B3B3B3;
    }
  }
}