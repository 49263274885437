#o-que {
  border-top: 3px solid @white;
  width: 100%;
  background: #F2F2F2;

  .roles {
    margin-top: 80px;

    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }

    .item-roles {
      width: 24.9%;
      min-height: 200px;
      max-height: 200px;
      float: left;
      padding: 0 12px;
      margin-bottom: 20px;

      .title-role {
        display: block;
        width: 70%;
        font-size: 30px;
        line-height: 28px;
        margin-bottom: 20px;
        position: relative;
        color: @gray-title;

        &::before {
          content: '';
          width: 35px;
          height: 2px;
          background: @secondary;
          display: block;
          position: absolute;
          left: 0;
          bottom: -10px;
        }
      }

      p {
        font-size: 12px;
        line-height: 13px;
        margin: 0;
        font-family: @font-quaternary;
      }
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .roles {
      .item-roles {
        width: 100%;
        padding: 0;
        min-height: unset;
        max-height: unset;
        margin-bottom: 40px;

        .title-roles {
          width: 100%;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}