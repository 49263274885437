#home {
  width: 100%;
  height: calc(100vh);
  position: relative;

  .inner-home {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .side-left {
    width: 30%;
    height: 100%;
    position: relative;
    background: @white;

    [class^="text-label-"]::before {
      bottom: 4px;
    }

    .call-to-action {
      width: 350px;
      height: 300px;
      position: absolute;
      top: 50%;
      right: -60px;
      margin-top: -145px;
      z-index: 98;

      p {
        font-size: 64px;
        line-height: 64px;
        font-weight: 500;
        font-family: @font-secondary;
        padding: 0;
        margin: 0;
      }
  
      a {
        display: block;
        margin-top: 25px;
        font-size: 16px;
        text-transform: uppercase;
        font-family: @font-tertiary;
      }
    }
  }

  .side-right {
    width: 70%;
    height: 100%;
    position: relative;
    background: #F2F2F2;

    img {
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  @media screen and (max-width: @screen-sm-max) {
    .inner-home {
      display: block;
    }

    .side-left {
      width: 100%;
      padding-top: 120px;
      background: #F2F2F2;

      .call-to-action {
        position: relative;
        margin-top: auto;
        top: auto;
        right: auto;
        width: 100%;
        height: 100%;
        padding: 0 15px;

        p {
          font-size: 50px;
          line-height: 50px;
        }

        a {
          width: 80%;
          height: 50px;
          padding: 10px;
          position: absolute;
          bottom: 30px;
          left: 30px;
          font-size: 20px;
          line-height: 26px;
          color: @white;
          text-align: right;
          font-family: @font-quaternary;
          letter-spacing: 10px;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background: #B3B3B3;
            .opacity(0.9);
            z-index: -1;
          }
        }
      }
    }

    .side-right {
      height: 55%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}